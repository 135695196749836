import { Controller } from 'stimulus';
import Rails from "@rails/ujs";



export default class extends Controller {
   
   static targets = ["frameSelection","currentFrameSelection"]

   static values = { token: String,  currentLocale: String }
   
    initialize() {
      console.log("Checkout stimulus controller");

    }


     showFrameSelection(event)
    {


      //Hide all other open dialoges
      this.frameSelectionTargets.forEach((frameSelection) => {frameSelection.style.display = "none"});

      console.log(event);
      console.log($(event.currentTarget).data("order-token-value"));
      var currentToken = $(event.currentTarget).data("order-token-value");
      console.log("Show frame selection", this.frameSelectionTargets);
      var currentTarget = this.frameSelectionTargets.filter((frameSelection)=> $(frameSelection).data("order-token-value") == currentToken)[0];
      currentTarget.style.display = "block";

    }

    changeFrame(event)
    {
      
      console.log("Change frame2", $(event.currentTarget).children(".frame_select_heading"));



      var currentToken = $(event.currentTarget).parent(".frame_select_content").data("order-token-value");
  

      this.currentFrameSelectionTargets.filter((frameSelection)=> $(frameSelection).data("order-token-value") == currentToken)[0].innerHTML = $(event.currentTarget).find(".frame_select_heading")[0].innerHTML;
      //Update the selection
      $(event.currentTarget).parent().children(".frame_select_row").removeClass("frame_selected");
      $(event.currentTarget).addClass("frame_selected");
      var newFrameValue = $(event.currentTarget).children(".frame_select_heading").data("frame-value");
      console.log("New frame value", newFrameValue);
      $('#order_frame').val(newFrameValue);
      this.frameSelectionTarget.style.display = "none";


      //Change preview
      $("#frame_preview").removeClass("black_frame white_frame natural_frame").addClass(newFrameValue);


      console.log();

      var frameBackground = $(event.currentTarget).parents(".new_cart_item_wrapper").find(".cart_v2_frame_background")
      frameBackground.removeClass("black_frame white_frame natural_frame").addClass(newFrameValue);


      //Hide all other open dialoges
      this.frameSelectionTargets.forEach((frameSelection) => {frameSelection.style.display = "none"})

      
      console.log("Update order token", currentToken);

      Rails.ajax({
        type: "post",
        url: "/orders/update_frame",
        data: `frame=${newFrameValue}&token=${currentToken}`,
        success: function(result) { 
            console.log("Frame successfully changed!",result.checkout_conclusion);
            $(".checkout_conclusion_wrapper").first().replaceWith(result.checkout_conclusion);
            $(".checkout_gift_wrapper").first().replaceWith(result.checkout_gift);
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      })
    }


    redeemCode(event)
    {
      console.log("Redeem code");

      var checkoutToken = $(event.currentTarget).data("checkout-token");
      var code = $('#code').val();


      Rails.ajax({
        type: "post",
        url: "/redeem_code",
        data: `code_value=${code}&checkout_token=${checkoutToken}`,
        success: function(result) { 
          console.log("Redeem result",result);
            $(".checkout_conclusion_wrapper").first().replaceWith(result.checkout_conclusion);
            $(".coupon_feedback_wrapper").show();
            if(result.redeemed_successful)
            {              
              $(".coupon_feedback_wrapper").text(this.getTranslatedMessageForKey("code_successfull"));
              $(".coupon_feedback_wrapper").css("color","#00a590");
            }
            else
            {
              $(".coupon_feedback_wrapper").text(this.getTranslatedMessageForKey("code_unsuccessfull"));
              $(".coupon_feedback_wrapper").css("color","red");
            }
            $("#total-checkout-price").text(result.new_price);
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      })

    }

    //This method shows the coupon field

    showCode(event)
    {
      console.log("Show code field");

      $("#voucher-form").removeClass("hidden");
      $(event.target).addClass("hidden");

     
    }


    //This method enables the user to switch between different shipping options
    selectHandlingOption(event)
    {
      console.log("Switch between shipping options");
      $(event.currentTarget).parent(".vertical_select_wrapper").children(".vertical_select_wrapper_checkout").removeClass("selected");
      $(event.currentTarget).parent(".vertical_select_wrapper").find(".option_select_indicator").removeClass("selected");
      $(event.currentTarget).find(".option_select_indicator").addClass("selected");
      $(event.currentTarget).addClass("selected");


      let dataShipmentType = $(event.currentTarget).data("shipmentType")

      console.log("Problem",$(event.currentTarget));
      console.log("priority",dataShipmentType);

      let locale = this.getCurrentGlobalLocale();

      Rails.ajax({
        type: "post",
        url: `/${locale}/user_change_handling_option`,
        data: `checkout_token=${this.tokenValue}&data_shipment_type=${dataShipmentType}`,
        success: function(result) { 
          console.log("Change handling option result",result);
          $(".checkout_conclusion_wrapper").first().replaceWith(result.checkout_conclusion);
          $("#total-checkout-price").text(result.new_price);
           
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      })

      

    }

    getTranslatedMessageForKey(key)
    {
      var messages = {};
      var fr_dict = {"code_successfull": "🎉 Code utilisé avec succès. 🎉", "code_unsuccessfull": "Le code n'est pas valide."};
      var de_dict = {"code_successfull": "🎉 Code erfolgreich eingelöst. 🎉", "code_unsuccessfull": "Der Code ist leider ungültig."};
      var it_dict = {"code_successfull": "🎉 Codice riscattato con successo. 🎉", "code_unsuccessfull": "Purtroppo il codice non è valido."}
      var es_dict = {"code_successfull": "🎉 Código canjeado con éxito. 🎉", "code_unsuccessfull": "El código no es válido, lamentablemente."}
      var ie_dict = {"code_successfull": "🎉 Code successfully redeemed. 🎉", "code_unsuccessfull": "The code is invalid, unfortunately."}
      var nl_dict = {"code_successfull": "🎉 Code succesvol ingewisseld. 🎉", "code_unsuccessfull": "De code is helaas ongeldig."}
      var dk_dict = {"code_successfull": "🎉 Kode indløst. 🎉", "code_unsuccessfull": "Koden er desværre ugyldig."}
      var fi_dict = {"code_successfull": "🎉 Koodi käytetty onnistuneesti 🎉", "code_unsuccessfull": "Koodi ei ole valitettavasti voimassa."};



      messages["fr"] = fr_dict;
      messages["de"] = de_dict;
      messages["nl"] = nl_dict;
      messages["dk"] = dk_dict;
      messages["es"] = es_dict;
      messages["ie"] = ie_dict;
      messages["it"] = it_dict;
      messages["dk"] = dk_dict;
      messages["at"] = de_dict;
      messages["fi"] = fi_dict;

      var currentLocale = this.currentLocaleValue;

      if(currentLocale.indexOf("ch") != -1)
      { 
        currentLocale = currentLocale.split("-")[1];
      }

      if(currentLocale.indexOf("be") != -1)
      { 
        currentLocale = currentLocale.split("-")[1];
      }


      console.log("Current locale")
      console.log(currentLocale);

      return messages[currentLocale][key]

    }

    //Get the current global language locale
    getCurrentGlobalLocale()
    {
      return document.head.querySelector("[name='global_current_locale']").content; 
    }

    //Open the local partner selection popup
    openSelector()
    {
      console.log("Close popup");
      document.getElementById("local_partner_selector").classList.remove("hidden");
    }

    //close the local partner selection popup
    closeSelector()
    {
      console.log("Close popup");
      document.getElementById("local_partner_selector").classList.add("hidden");
    }

     //Select a local pickup partner
    selectLocalPickup(event)
    {
      console.log("Select a local pickup", event);
      console.log("Select a local pickup", event.currentTarget);
      console.log("Select a local pickup", event.currentTarget.dataset.partnerId);
      event.currentTarget.parentNode.getElementsByClassName("option_select_indicator").forEach(function (select_indicator) {
        select_indicator.classList.remove("selected");
      });

      event.currentTarget.firstElementChild.firstElementChild.classList.add("selected");

      document.getElementById("local_partner_selector").setAttribute('data-current-partner-id', event.currentTarget.dataset.partnerId);


    }

    //Confirm a local pickup partner
    confirmLocalPickup()
    {
      console.log("Confirm local pickup", );

      let locale = this.getCurrentGlobalLocale();
      let selectedPartnerId = document.getElementById("local_partner_selector").dataset.currentPartnerId;



      Rails.ajax({
        type: "post",
        url: `/${locale}/user_select_local_pickup_option`,
        data: `checkout_token=${this.tokenValue}&pickup_point_id=${selectedPartnerId}`,
        success: function(result) { 
          console.log("Change handling option result",result);
          $(".checkout_conclusion_wrapper").first().replaceWith(result.checkout_conclusion);
          $("#total-checkout-price").text(result.new_price);
          $("#checkout_handling_options").replaceWith(result.checkout_handling_options);

         
          this.closeSelector();
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      })

    }

  
   
}