import { Controller } from 'stimulus';
import Rails from "@rails/ujs";


import Uppy from '@uppy/core'
import FileInput from '@uppy/file-input'
import ActiveStorageUpload from '@michaeldopfer/uppy-activestorage-upload'

export default class extends Controller {
   
   	static values = { submitted: Boolean, checkout: String, currentLocale: String, uploadCount: Number }

    static targets = ["frameSelection","currentFrameSelection", "printNumberSelection", "currentOrientationSelection", "orientationSelection", "currentPrintNumberSelection", "modal", "afterUploadModal", "imageProcessing", , "imageQualityCheckButton", "duplicateNumberSelection"]
 
    initialize() {
      console.log("Order stimulus controller");

      console.log("Current locale", this.currentLocaleValue);
          
      const uppyElement = document.querySelector('[data-uppy]');
      this.setupUppy(uppyElement);


     
        

    }

    setupUppy(element)
    {
        

        let form = element.closest('form')
          let direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
          let field_name = element.dataset.uppy
          const csrfToken = document.querySelector('meta[name="csrf-token"]').content

          let uppy = new Uppy({
            autoProceed: true,
            allowMultipleUploads: false,
            restrictions: {
                maxFileSize: 30000000,
                maxNumberOfFiles: 1,
                minNumberOfFiles: 1,
                allowedFileTypes: ['image/*']
            }
          })


          uppy.use(ActiveStorageUpload, {
            directUploadUrl: direct_upload_url,
            headers: { "X-CSRF-Token": csrfToken }
          })


           let preview = element.querySelector('[data-behavior="uppy-preview"]')
           var buttonText = this.getTranslatedMessageForKey("add_image")
           if (preview.src != "") {
              console.log("Preview",preview);
               buttonText = this.getTranslatedMessageForKey("replace_image")
            }


        
       

          uppy.use(FileInput, {
            target: '#uppy-root',
            pretty: true,
            locale: {
            strings: {
              chooseFiles: buttonText
            }
            }
          })

           uppy.on('upload', (data) => {
              $(".progress_bar_progress_wrapper").removeClass("hidden");
              preview.src = "";
           });

          uppy.on('upload-success', (file, response) => {
            console.log("Upload successful");
            preview.setAttribute('data-do-not-submit', false);


          });

          uppy.on('upload-error', (file, error, response) => {
            console.log("Error", error);
            $(".progress_bar_progress_wrapper").addClass("hidden");
            window.alert('Es ist ein Fehler aufgetreten. Bitte versuche es erneut. Error: ' + error);
            preview.setAttribute('data-do-not-submit', true);

            var element =  document.getElementById("check-photo-quality")
            if(typeof(element) != 'undefined' && element != null)
            {
              element.classList.add("hidden");
            }
          


          })


           uppy.on('upload-progress', (file, progress) => {
              // file: { id, name, type, ... }
              // progress: { uploader, bytesUploaded, bytesTotal }
              var percentage = Math.round((progress.bytesUploaded/progress.bytesTotal) * 100)
              document.getElementById("progress-counter").innerHTML = `${percentage}%`
              document.getElementById("progress-bar").style.width = `${percentage}%`
              console.log("CustomerProgress",percentage)
            });
          

           

           $(".uppy-Dashboard-poweredBy").hide();
           $(".uppy-Dashboard-inner").css("background-color","transparent");
           
         

          uppy.on('complete', (result) => {
            element.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element))

            result.successful.forEach(file => {
              this.appendUploadedFile(element, file, field_name)
              this.setPreview(element, file)
            })

            uppy.cancelAll();



            
          });



    }

    appendUploadedFile(element, file, field_name) {


      console.log("File",file);

      const hiddenField = document.createElement('input')

      hiddenField.setAttribute('type', 'hidden')
      hiddenField.setAttribute('name', field_name)
      hiddenField.setAttribute('data-pending-upload', true)
      hiddenField.setAttribute('value', file.response.signed_id)

      element.appendChild(hiddenField)
    }

    setPreview(element, file) {
      let preview = element.querySelector('[data-behavior="uppy-preview"]')

      this.imageProcessingTarget.classList.remove("hidden");

      if (preview) {
        Rails.ajax({
            type: "post",
            url: "/order_preview_url",
            data: `key=${file.response.key}`,
            success: function(url) { 
              preview.src = url
              $(".progress_bar_progress_wrapper").addClass("hidden");

              this.imageProcessingTarget.classList.add("hidden");
              this.imageQualityCheckButtonTarget.classList.remove("hidden")
              this.uploadCountValue ++;

              //Only display the popup on the first upload
              if(this.uploadCountValue == 1)
              {
                 setTimeout(function(){
                  //Only display when the button is visible -> Only on mobile
                  if(this.imageQualityCheckButtonTarget.offsetHeight > 0)
                  {
                    this.afterUploadModalTarget.classList.remove("hidden");
                  }
                  
                  }.bind(this), 2000);
              }

              //set the identification token 
              document.getElementById("whatsapp-check-button").href = document.getElementById("whatsapp-check-button").href + "%20" + "(" + file.response.key.slice(-5) + ")"

            }.bind(this),
            error: function(data)
            {
              console.log("Something went wrong!");
              preview.src = "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSpj0DBTVsaja01_xWh37bcutvpd7rh7zEd528HD0d_l6A73osY"
              $(".progress_bar_progress_wrapper").addClass("hidden");
            }.bind(this)
          })

        $("#upload-missing-error").hide();
      }
    }

    showFrameSelection(event)
    {
      console.log("Show frame selection", this.frameSelectionTarget);
      this.frameSelectionTarget.style.display = "block";

       // Hide the other selection modal
       this.printNumberSelectionTarget.style.display = "none"; 

    }

    changeFrame(event)
    {
      
      console.log("Change frame2", $(event.currentTarget).find(".frame_select_heading")[0].innerHTML);
  

      this.currentFrameSelectionTarget.innerHTML = $(event.currentTarget).find(".frame_select_heading")[0].innerHTML;
      //Update the selection
      $(event.currentTarget).parent().children(".frame_select_row").removeClass("frame_selected");
      $(event.currentTarget).addClass("frame_selected");
      var newFrameValue = $(event.currentTarget).find(".frame_select_heading").data("frame-value")
      $('#order_frame').val(newFrameValue);
      this.frameSelectionTarget.style.display = "none";


      //Change preview
      $("#frame_preview").removeClass("black_frame white_frame natural_frame").addClass(newFrameValue);

      if(newFrameValue == "no_frame")
      {
        
      }


      this.calculatePrice()

    }


  showOrientationSelection()
  {
      this.orientationSelectionTarget.style.display = "block";

       // Hide the other selection modal
       this.printNumberSelectionTarget.style.display = "none"; 
       this.frameSelectionTarget.style.display = "none";
  }

  changeOrientation(event)
  {

    console.log("Selected orientation: ", $(event.currentTarget).find(".frame_select_heading")[0].innerHTML);
      this.currentOrientationSelectionTarget.innerHTML = $(event.currentTarget).find(".frame_select_heading")[0].innerHTML;
      //Update the selection
      $(event.currentTarget).parent().children(".frame_select_row").removeClass("frame_selected");
      $(event.currentTarget).addClass("frame_selected");



      var orientation = $(event.currentTarget).find(".frame_select_heading").data("orientation-value")
      document.getElementById('order_orientation').value = orientation;
      console.log("Orientation", orientation);

      //Change the orientation of the preview in the configurator 
      if (orientation == "auto" || orientation == "vertical") {
        document.getElementById("preview_wrapper").classList.remove("horizontal");
        document.getElementById("preview_sample_picture").classList.remove("horizontal");
        document.querySelector(".editor_slider").classList.remove("horizontal");
        document.querySelector(".frame_preview").classList.remove("horizontal");
      } else {
        document.getElementById("preview_wrapper").classList.add("horizontal");
        document.getElementById("preview_sample_picture").classList.add("horizontal");
        document.querySelector(".editor_slider").classList.add("horizontal");
        document.querySelector(".frame_preview").classList.add("horizontal");
      }

      if (orientation == "auto") {
        document.getElementById("orientation_default_checkmark").style.display = "block";
      } else {
        document.getElementById("orientation_default_checkmark").style.display = "none";
      }
      
      this.orientationSelectionTarget.style.display = "none";

      //Resize the slider
      Webflow.destroy();
      Webflow.ready();
  }



    showPrintNumberSelection(event)
    {
      console.log("Show frame selection 1", this.frameSelectionTarget);
      console.log("Show frame selection 2", this.currentFrameSelectionTarget);
      console.log("Show print number selection", this.printNumberSelectionTarget);
      this.printNumberSelectionTarget.style.display = "block";

      // Hide the other modal 
      this.frameSelectionTarget.style.display = "none";
    }
    

    changePrintNumber(event)
    {
      
      console.log("Change frame2", $(event.currentTarget));
  

      this.currentPrintNumberSelectionTarget.innerHTML = $(event.currentTarget).find(".frame_select_heading")[0].innerHTML;
      //Update the selection
      $(event.currentTarget).parent().children(".frame_select_row").removeClass("frame_selected");
      $(event.currentTarget).addClass("frame_selected");
      var newFrameValue = $(event.currentTarget).find(".frame_select_heading").data("print-number-value")
      $('#order_number_of_prints').val(newFrameValue);

      this.printNumberSelectionTarget.style.display = "none";

      this.calculatePrice()

    }




    sizeChanged()
    {
      console.log("Size changed");



      this.calculatePrice()
    }

    colorChanged(event)
    {
      console.log("Color changed", event.currentTarget.dataset.colorName);
      var button = $(event.currentTarget).next();

      $("#imageBackground").css('cssText', 'background-color: ' + button.css("background-color") + "!important;");

      //Set the color name in the editor
      document.getElementById('color-name').innerHTML = event.currentTarget.dataset.colorName
    }

    hoodieColorChanged(event)
    {
      //Set the color name in the editor
      document.getElementById('color-name').innerHTML = event.currentTarget.dataset.colorName
      this.generateHoodiePreview();
    }



    showAfterUploadPopup(event)
    {
      console.log("Show after upload popup");

      this.afterUploadModalTarget.classList.remove("hidden");
    }
    



    calculatePrice()
    {


      let order_type = $('#order_type').val();
      let numberOfPersons = $("#order_number_of_persons").val();
      let size = $('input[name="order[size]"]:checked').val();
      let frame = $('#order_frame').val();
      let numberOfPrints = $('#order_number_of_prints').val();
      let sleeveText = $('#order_additional_information_sleeve_embroidery').val();
      let locale = this.getCurrentGlobalLocale();


      if(size == "digital_file")
      {
        $(".hide_with_digital_file").hide();
      }
      else
      {
        $(".hide_with_digital_file").show();
      }

     

      console.log("Frame", frame);
      Rails.ajax({
            type: "post",
            url: `/${locale}/calculate_price`,
            data: `number_of_persons=${numberOfPersons}&size=${size}&frame=${frame}&number_of_prints=${numberOfPrints}&order_type=${order_type}&sleeve_text=${sleeveText}`,
            success: function(result) { 
                console.log("New price",result)
                this.adjustPrice(result);
            }.bind(this),
            error: function(data)
            {
              console.log("Something went wrong!");
            }
          })
    }

    adjustPrice(result)
    {

      $(".add_to_cart_price").text(result.new_price);
      $("#additional_price").text(result.price_increase);
      $("#summary_partial").replaceWith(result.summary_partial);

      console.log("Frame Result",result);


      console.log("Has frame property",this.hasCurrentFrameSelectionTarget);

      //Only update the frame in case it is a normal order
      if(this.hasCurrentFrameSelectionTarget)
      {
        $(this.currentFrameSelectionTarget).find(".frame_price").text(result.current_frame_price);
        $(this.currentFrameSelectionTarget).find(".frame_info").text(result.current_frame_info);

        
        for(var frame_key in result.frame_prices)
        {
          console.log("Frame key", frame_key)
          console.log("Value", result.frame_prices[frame_key])
        
          console.log("Element", $(".frame_select_content").find(`[data-frame-value="${frame_key}"]`).find(".frame_price"));
          $(".frame_select_content").find(`[data-frame-value="${frame_key}"]`).find(".frame_price").text(result.frame_prices[frame_key]);
          $(".frame_select_content").find(`[data-frame-value="${frame_key}"]`).find(".frame_info").text(result.frame_info[frame_key]);
        }

        
      }

      //Update the additional price
      if(result.price_increase != "+ 0 €")
      {
       $("#additional_price").css("display","block");
      }
      else
      {
        $("#additional_price").css("display","none");
      }



    }


    decreaseNumberOfPersons(event)
    {
      console.log("Decrease");
      var currentValue = parseInt($("#order_number_of_persons").val());
      currentValue = currentValue - 1; 
      if(currentValue<0)
      {
       currentValue = 0;
        
      }
      else if(currentValue==0)
      {
       $("#decrease_number_of_persons").addClass("non_active");
        $("#person-missing-error").show();
      }
      $("#order_number_of_persons").val(currentValue);
      $("#persons_counter").text(currentValue);
      this.calculatePrice()

    }

    increaseNumberOfPersons(event)
    {
     var currentValue = parseInt($("#order_number_of_persons").val());
     currentValue = currentValue + 1; 
     $("#order_number_of_persons").val(currentValue);
     $("#persons_counter").text(currentValue);
     $("#decrease_number_of_persons").removeClass("non_active");
     $("#person-missing-error").hide();
     this.calculatePrice()
    }

    showFrameInfo()
    {
      console.log("Show frame info");
      this.showModal("frames");
    } 

    showPersonsInfo()
    {
      console.log("Show persons info");
      this.showModal("persons");
    }

    showUploadInfo()
    {
      console.log("Show upload info");
      this.showModal("upload");
    }

    showAdditionalPrintsInfo()
    {
      console.log("Show additional prints info");
      this.showModal("prints");

     

    }

    showSizeInfo()
    {
       this.showModal("size");
    }
  

    showModal(type)
    {

       // Hide the other modals if they exist 

       console.log("Test problem", this.hasOwnProperty("frameSelectionTarget"));
        console.log("Test problem", this.hasOwnProperty("printNumberSelectionTarget"));

      if(this.hasFrameSelectionTarget)
      {
        this.frameSelectionTarget.style.display = "none";
      }

      if(this.hasPrintNumberSelectionTarget)
      {
        this.printNumberSelectionTarget.style.display = "none"; 
      }
     
    


      console.log("ModalTarget",this.modalTarget);
      this.modalTarget.classList.remove("hidden");

      let locale = this.getCurrentGlobalLocale();
      
       Rails.ajax({
        type: "get",
        url: `/${locale}/get_order_detail_modal?type=${type}`,
        data: null,
        success: function(result) { 
            console.log("Success", this.modalContentTarget);
            console.log("result",result);
            document.getElementById("modalContent").outerHTML = result.modal_content;

            //Frame slider workaround
            if(type == "frames")
            {
              Webflow.destroy();
              Webflow.ready();
            }

        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      });
    }

    closeModal()
    {
      console.log("Close modal");
      //Close all possible modals 
      if(this.hasModalTarget)
      {
        this.modalTarget.classList.add("hidden");
      }
      

      if(this.hasAfterUploadModalTarget)
      {
        this.afterUploadModalTarget.classList.add("hidden");
      }
      
      document.getElementById("modalContent").innerHTML = "";
    }


    //Begin custom hoodie methods
    embroidingPositionChanged(event)
    {
      console.log("Embroiding position changed");
      console.log(event.currentTarget);
      console.log(event.currentTarget.dataset.embroideryPosition);
      console.log(event.currentTarget.parentElement);
      let wrapper = event.currentTarget.parentElement; 
      let visibleIcon = wrapper.querySelector('.checked_icon:not(.hidden)');
      visibleIcon.classList.add("hidden");
      let hiddenIcon = event.currentTarget.querySelector('.checked_icon');
      hiddenIcon.classList.remove("hidden");
      
      
      console.log("Icons", visibleIcon);
      console.log("Icons", hiddenIcon);

      //Update the value in the order form 
      document.getElementById("order_additional_information_embroiding_position").value = event.currentTarget.dataset.embroideryPosition;
      
      this.generateHoodiePreview(event.currentTarget.dataset.embroideryPosition);

    }

    threadColorChanged(event)
    {
      console.log("Thread color changed");
      document.getElementById('thread-color-name').innerHTML = event.currentTarget.dataset.threadColorName;

      this.generateHoodiePreview();
    }

    showDuplicateNumberSelection(event)
    {
 
      this.duplicateNumberSelectionTarget.style.display = "block";

    }

    changeDuplicateNumber(event)
    {
      
      console.log("Change frame2", $(event.currentTarget));
  

      this.currentPrintNumberSelectionTarget.innerHTML = $(event.currentTarget).find(".frame_select_heading")[0].innerHTML;
      //Update the selection
      $(event.currentTarget).parent().children(".frame_select_row").removeClass("frame_selected");
      $(event.currentTarget).addClass("frame_selected");
      var newFrameValue = $(event.currentTarget).find(".frame_select_heading").data("print-number-value")
      $('#order_number_of_prints').val(newFrameValue);

      this.duplicateNumberSelectionTarget.style.display = "none";

      this.calculatePrice()

    }

    changeEmbroidery()
    {
      console.log("Embroidery changed");
      if (!event.currentTarget.classList.contains('selected')) {
         document.querySelectorAll('.font_selection_button').forEach(function(button)
          {
              button.classList.remove('selected');
          });
         event.currentTarget.classList.add('selected');
      }

      console.log("Test", event.currentTarget.dataset.withEmbroidery);
      console.log(Boolean(event.currentTarget.dataset.withEmbroidery) == true);

      if(event.currentTarget.dataset.withEmbroidery === "true")
      {
        document.getElementById("embroidery_text").classList.remove("hidden");
      }
      else
      {

        document.getElementById("embroidery_text").classList.add("hidden");
        //Also reset the value
        document.getElementById('order_additional_information_sleeve_embroidery').value = ""
      }

      this.calculatePrice();

    }


    generateHoodiePreview()
    {

      document.getElementById("hoodie_loading_spinner").classList.remove("hidden");
      var position = document.getElementById("order_additional_information_embroiding_position").value;
      console.log("Change hoodie preview");
      var thread_color = "white";
      console.log("Thread color",thread_color);
      var hoodie_color = document.querySelector('input[name="order[background_color]"]:checked').value;

      //Show first page slider
      var clickEvent = new MouseEvent("click", {
          "view": window,
          "bubbles": true,
          "cancelable": false
      });
      document.getElementsByClassName("w-slider-dot")[0].dispatchEvent(clickEvent);
     
      
      var newImg = new Image;
      newImg.onload = function() {
          document.getElementById("main_hoodie_preview").src = this.src;
          document.getElementById("hoodie_loading_spinner").classList.add("hidden");
      }
      newImg.src = `/generate_hoodie_preview?position=${position}&thread_color=${thread_color}&hoodie_color=${hoodie_color}`;

    }


    //END custom hoodie methods 


    //Helper methods

    getTranslatedMessageForKey(key)
    {
      var messages = {};
      var fr_dict = {"add_image": "Sélectionner une image", "replace_image": "Changer l'image"};
      var de_dict = {"add_image": "Bild auswählen", "replace_image": "Bild ändern"};
      var ie_dict = {"add_image": "Upload image", "replace_image": "Change image"};
      var en_dict = {"add_image": "Select an image", "replace_image": "Change image"};
      var es_dict = {"add_image": "Seleccionar una imagen", "replace_image": "Cambiar la imagen"};
      var it_dict = {"add_image": "Seleziona un'immagine", "replace_image": "Cambia immagine"};
      var nl_dict = {"add_image": "Selecteer een afbeelding", "replace_image": "Afbeelding veranderen"};
      var dk_dict = {"add_image": "Vælg billede", "replace_image": "Ændre billede"};
      var se_dict = {"add_image": "Välj bild", "replace_image": "Byt bild"};
      var pl_dict = {"add_image": "Wybierz obraz", "replace_image": "Zmień obraz"};
      var fi_dict = {"add_image": "Valitse kuva", "replace_image": "Vaihda kuva"};




      messages["fr"] = fr_dict
      messages["de"] = de_dict
      messages["en"] = en_dict
      messages["es"] = es_dict
      messages["it"] = it_dict
      messages["nl"] = nl_dict
      messages["dk"] = dk_dict
      messages["ie"] = ie_dict
      messages["se"] = se_dict
      messages["pl"] = pl_dict
      messages["at"] = de_dict
      messages["fi"] = fi_dict

      var currentLocale = this.currentLocaleValue;

      if(currentLocale.indexOf("ch") != -1)
      { 
        currentLocale = currentLocale.split("-")[1];
      }

      if(currentLocale.indexOf("be") != -1)
      { 
        currentLocale = currentLocale.split("-")[1];
      }

      return messages[currentLocale][key]

    }

    //Get the current global language locale
    getCurrentGlobalLocale()
    {
      return document.head.querySelector("[name='global_current_locale']").content; 
    }
}