// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs";
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

//require("controllers")
require("controllers/index")


//require("jquery")
require("packs/main")
require("packs/widget")
require("packs/webflow")
require("packs/webflow_turbo")


//require("typeface-source-sans-pro");
//require("typeface-montserrat");
//require("typeface-bitter");

import '@fontsource-variable/source-sans-3';



document.addEventListener('turbolinks:load', () => {
 

  $(".submit-button").click(function() {
     console.log("Submit form");
	   $('form').submit();
	});



  var accordion = (function(){
  
    var $accordion = $('.js-accordion');
    var $accordion_header = $accordion.find('.js-accordion-header');
    var $accordion_item = $('.js-accordion-item');

    console.log("Accordion", $accordion);
   
    // default settings 
    var settings = {
      // animation speed
      speed: 400,
      
      // close all other accordion items if true
      oneOpen: false,
    };
      
    return {
      // pass configurable object literal
      init: function($settings) {
        $accordion_header.on('click', function() {
          accordion.toggle($(this));
        });
        
        $.extend(settings, $settings); 
        
        // ensure only one accordion is active if oneOpen is true
        if(settings.oneOpen && $('.js-accordion-item.active').length > 1) {
          $('.js-accordion-item.active:not(:first)').removeClass('active');
        }
        
        // reveal the active accordion bodies
        $('.js-accordion-item.active').find('> .js-accordion-body').show();
      },
      toggle: function($this) {
              
        if(settings.oneOpen && $this[0] != $this.closest('.js-accordion').find('> .js-accordion-item.active > .js-accordion-header')[0]) {
          $this.closest('.js-accordion')
                 .find('> .js-accordion-item') 
                 .removeClass('active')
                 .find('.js-accordion-body')
                 .slideUp()
        }
        
        // show/hide the clicked accordion item
        $this.closest('.js-accordion-item').toggleClass('active');
        $this.next().stop().slideToggle(settings.speed);
      }
    }
  })();

  //Setup accordion
  accordion.init({ speed: 300, oneOpen: true });

  console.log("accordion",accordion);


  var headerElements = $(".header_element_wrapper");
  console.log("HeaderElement,",headerElements.first().is(":visible"));
  var currentVisibleIndex = 0;
  if(!headerElements.first().is(":visible"))
  {
    $(headerElements[currentVisibleIndex]).fadeIn();
    setInterval(changeHeader, 5000);
  }
  function changeHeader()
  {
    $(headerElements[ currentVisibleIndex]).fadeOut(400,function(){
      currentVisibleIndex += 1;
      if(currentVisibleIndex==3)
      {
        currentVisibleIndex = 0;
      }
      $(headerElements[ currentVisibleIndex]).fadeIn();
    });
  }



   


   $(".woot-widget-bubble").click(function() {

    window.$chatwoot = null;
     window.chatwootSDK.run({
              websiteToken: 'pkzNYPubAQGwW1HsEkWcy1NT',
              baseUrl:"https://www.miroar.chat"
      });

     window.$chatwoot.toggle();
     
    });


   $(".wa_button").click(function() {

      console.log("Show popup");
      $(".wa_chat_wrapper").toggleClass("hidden");
    });
 
    $("#close-wa-popup").click(function(){
      $(".wa_chat_wrapper").addClass("hidden");
    });


});








